<template>
  <div class="wrap">
    <div class="filter">
      <el-input
        style="width: 400px"
        placeholder="请输入对应的筛选"
        v-model="search_str" 
      >
        <el-select
          v-model="select_index"
          style="width: 150px"
          slot="prepend"
          placeholder="请选择查询方式"
        >
          <el-option
            v-for="(item, index) in search_sel"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <el-button
          @click="search"
          slot="append"
          icon="el-icon-search"
        ></el-button>
      </el-input>

      <div class="get-btn">
        <el-dropdown
          v-if="typeof status === 'number'"
          split-button
          @command="commandHandle"
          type="text"
          :style="{ backgroundColor: colorStatus[status].color }"
        >
          {{ colorStatus[status].type }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              class="dropdown-item"
              :command="index"
              v-for="(item, index) in colorStatus.slice(0)"
              :key="index"
              >{{ item.type }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        v-for="(item, index) in tableData_cell"
        :key="index"
        :prop="item.value"
        :label="item.title"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button class="btn" size="mini" @click="redact(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog width="150" title="编辑" :visible.sync="dialogFormVisible">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="essentialInformation">
          <el-form :model="formamend">
            <el-form-item label="投递人姓名" label-width="120">
              <el-input
                v-model="formamend.elite_User.weixin_nickname"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="投递人电话" label-width="120">
              <el-input
                v-model="formamend.elite_User.username"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="职位名称" label-width="120">
              <el-input
                v-model="formamend.jd_title"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司名称" label-width="120">
              <el-input
                v-model="formamend.company_name"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="经纪人姓名" label-width="120">
              <el-input
                v-model="formamend.elite_agent.truename"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="经纪人电话" label-width="120">
              <el-input
                v-model="formamend.elite_agent.mobile"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="入职时间" label-width="120">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="formamend.start_time"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 250px"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="佣金" label-width="120">
              <el-input v-model="formamend.money" autocomplete="off"></el-input>
            </el-form-item>

            <!--
             <el-form-item label="是否达到发放佣金条件">
                <el-switch
                  active-color="#58362A"
                  v-model="formamend.status" 
                  active-text="是"
                  inactive-text="否"
                ></el-switch>
              </el-form-item>
--->

            <el-form-item label="发放佣金时间" label-width="120">
              <el-date-picker
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                v-model="formamend.close_time"
                style="width: 250px"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="备注" label-width="120">
              <el-input
                v-model="formamend.remarks"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="btn" @click="amend">确 定</el-button>
      </div>
    </el-dialog>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pageTotal"
        :page-size="20"
        :current-page="pageNow"
        @current-change="pageNowHandler"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { get_elite_money_all, update_money } from "@/api/agent/index.js";
import { showCity } from "@/api/resume";
import axios from "axios";
export default {
  data() {
    return {
      //总页码
      pageTotal: null,
      pageNow: 1,
      search_str: "",
      status: 0,
      search_sel: [
        { label: "职位名称", value: "jd_title" },
        { label: "公司名称", value: "company_name" },
        { label: "经纪人手机号", value: "mobile" },
        { label: "投递简历手机号", value: "td_mobile" },
      ],
      select_index: "",
      // 表单数据处理
      tableData_cell: [
        { title: "经纪人姓名", value: "elite_agent.truename" },
        { title: "经纪人手机号", value: "elite_agent.mobile" },
        { title: "职位名称", value: "jd_title" },
        { title: "公司名称", value: "company_name" },
        { title: "投递用户", value: "elite_User.weixin_nickname" },
        { title: "投递用户手机号", value: "elite_User.username" },
        { title: "是否投递简历", value: "is_delivery" },
        { title: "投递时间", value: "create_time" },
        { title: "入职时间", value: "start_time" },
      ],
      tableData: [],
      // 编辑
      indry: [],
      city: [],
      is_seed: false,
      dialogFormVisible: false,
      formamend: {
        id: "",
        jd_title: "",
        company_name: "",
        elite_agent: [],
        elite_User: [],
        start_time: "",
        close_time: "",
        remarks: "",
        status: false,
        money: "",
      },
      place_data: [],
      industry_name: "",
      user_detail_id: "",
      form: {},
      activeName: "essentialInformation",
      id: "",

      //不合格，发offer,未入职，可复试
      colorStatus: [
        { color: "#ccc", type: "未标记" },
        { color: "#FF435A", type: "不合格" },
        { color: "#ff9900", type: "可复试" },
        { color: "#3EFF59", type: "发offer" },
        { color: "#1D7BD8", type: "未入职" },
      ],
    };
  },
  mounted() {
    axios.get("/static/industry.json").then((res) => {
      this.indry = res;
    });
    showCity({}).then((res) => {
      console.log(res);
      this.city = res.data;
    });
  },
  methods: {
    //下拉菜单
    commandHandle(command) {
      console.log(command);

      this.status = command;

      // getResumeStatus(data).then((res) => {
      //   if (res.code === 0) {
      //     this.getData()
      //   }
      // })

      this.search(1);
    },

    getTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    onSubmit() {
      let obj = {
        company_user: this.search_str,
        vip_end_time: this.getTime(this.form.date),
        is_seed: this.form.is_seed == true ? 1 : 0,
      };

      amend_date(obj).then((res) => {
        if (!res.code) {
          this.dialogFormVisible = false;
          this.$message.success(res.msg);
          this.search(1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(val) {
      console.log(val);
    },
    areaChange(e) {
      console.log(e);
    },
    indusChange(e) {
      console.log(e);
    },
    search(e) {
      let search_data = {
        status: this.status,
        page: this.pageNow,
      };

      if (this.select_index && this.search_str) {
        search_data = {
          [this.select_index]: this.search_str,
          status: this.status,
          page: this.pageNow,
        };
      }

      get_elite_money_all({ search_data }).then((res) => {
        console.log("sssssssssssssssss");

        if (!res.code) {
          if (res.data.length) {
            // pageTotal

            res.data.map((item) => {
              console.log(item);
              item.is_delivery = item.is_delivery ? "是" : "否";
            });
          }
          this.pageTotal = res.all_count;
          this.tableData = res.data;
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        console.log(res);
      });
    },

    redact(e) {
      this.activeName = "essentialInformation";
      this.dialogFormVisible = true;
      this.id = e.id;
      this.formamend = {
        id: e.id,
        jd_title: e.jd_title,
        company_name: e.company_name,
        elite_agent: e.elite_agent,
        elite_User: e.elite_User,
        start_time: e.start_time ? e.start_time : "",
        close_time: e.close_time ? e.close_time : "",
        remarks: e.remarks,
        status: e.status == 1 ? true : false,
        money: e.money,
      };

      console.log(this.formamend);
    },

    amend() {
      console.log("dddddddddd", this.formamend.status);

      if (this.activeName == "essentialInformation") {
        let obj = {
          id: this.formamend.id,
          start_time: this.formamend.start_time
            ? this.formamend.start_time
            : "",
          close_time: this.formamend.close_time
            ? this.formamend.close_time
            : "",
          remarks: this.formamend.remarks ? this.formamend.remarks : "",
        };

        update_money(obj).then((res) => {
          if (!res.code) {
            this.dialogFormVisible = false;
            this.$message.success(res.msg);
            this.search(1);
          } else {
            this.$message.error(res.msg);
          }
        });
        return false;
      } else if (this.activeName == "MemberExpiration") {
        this.onSubmit();
        return false;
      } else {
      }
    },

    pageNowHandler(page) {
      if (this.pageNow != page) {
        this.loading = true;

        this.pageNow = page;
        this.search(1); 
      }
    },
  },

  created() {
      //判断是否有独立管理经纪人权限
      if (sessionStorage.getItem("is_gl_agent") != 1) {
      return this.$confirm("您没有管理权限，请联系管理员！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push({
            path: "/company",
          });
        })
        .catch((e) => {
          this.$router.push({
            path: "/company",
          });
        });
    }
    
    this.search(1);
  },
};
</script>

<style scoped>
.pagination {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.wrap {
  padding-top: 54px;
}
.filter {
  margin-bottom: 10px;
}
.wrap_box1 {
  display: flex;
}

.el-input {
  width: 60%;
  margin-right: 10px;
}

.el-form-item {
  width: 50%;
  float: left;
}

.dropdown-item {
  padding: 0 10px 0 10px;
}

.get-btn-wrap {
  width: 100%;
  height: 50px;
}
.get-btn {
  float: left;
}

.get-btn::after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}
</style>
